
export async function showMessageBox(_this, title, message) {
    return new Promise((resolve, reject) => {
        try {
            const h = _this.$createElement
            // Using HTML string
            const titleVNode = h('div', { domProps: { innerHTML: title } })
            // More complex structure
            const messageVNode = h('div', { class: ['foobar'] }, [
                h('p', { class: ['text-left'] }, [
                    message
                ])
            ])
            const rta = _this.$bvModal.msgBoxConfirm([messageVNode], {
                title: [titleVNode],
                centered: true,
                size: 'sm',
                okTitle: 'Si',
                cancelTitle: 'No'
            })
            resolve(rta)
        } catch (error) {
            reject(error)
        }
    })
}

export async function showNotificationPopup(notificationData){
    return new Promise((resolve,reject) => {
        try {
            if ('Notification' in window) {
                console.log('notificacion en window')
                if(Notification.permission === 'granted'){
                    var options = {                
                        body: `${notificationData.message}`,
                        silent: false,
                        requireInteraction: true,
                    };
                    new Notification(`${notificationData.title}`, options);
                    resolve(true)
                }else if(Notification.permission !== 'denied'){
                    Notification.requestPermission()
                  .then(function (permission) {
                    if (permission === 'granted') {
                        var options = {                
                            body: `${notificationData.message}`,
                            silent: false,
                            requireInteraction: true,
                        };
                        new Notification(`${notificationData.title}`, options);
                        resolve(true)
                    }else {
                        console.error("Permiso denegado para mostrar notificaciones.");
                    }
                  });
                }
            } else {
            alert('Las notificaciones no son compatibles con tu navegador.');                
            }
        } catch (error) {
            reject(error)
        }
    })
}