import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment-timezone';
import ChatService from '../services/ChatService'
import {showNotificationPopup} from '../shared/utils'
import io from 'socket.io-client';
import NoveltiesService from '../services/NoveltiesService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // token: '',
    userLogged: JSON.parse(localStorage.getItem('userLogged')),
    snackbarData: {
      error: false,
      message: '',
      variant: ''
    },
    marginLeftNavbar: '225px',
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false
    },
    chatMessages: [],
    chatConversations: [],
    chatSelected: null,
    noveltyToEdit: null,
    listNovelties: [],
    pagination: {
      currentPage: 1,
      totalRows: 0,
      perPage: 10
    }
  },
  getters: {
    isLogged(state) {
      return state.userLogged ?? undefined
    },
    getUserLogged(state) {
      return state.userLogged
    },
    marginLeftNavbar(state) {
      return state.marginLeftNavbar;
    },
    userCan: (state) => (permissionName) => {
      const found = state.userLogged.permissions.find(permission => permission.name == permissionName)
      return found ? true : false
    }
  },
  mutations: {

  },
  actions: {
    login({ state }, payload) {
      // state.token = payload
      // localStorage.setItem('token', payload)
      state.userLogged = payload
      localStorage.setItem('userLogged', JSON.stringify({ ...payload, dateLogged: moment().valueOf() }))
    },
    logout({ state }) {
      // state.token = null
      // localStorage.removeItem('token')
      state.userLogged = undefined
      localStorage.removeItem('userLogged')

    },
    toggleSidebar({ state }) {
      state.marginLeftNavbar = state.marginLeftNavbar == '0px' ? '225px' : '0px'
    },
    setChatMessages({ state }, payload) {
      state.chatSelected = payload.chat
      if (payload.chatId) {
        state.chatMessages = payload.messages
      }
    },
    async setChatConversations({ state }) {
      let listConversations = await ChatService.getAllConversations()
      state.chatConversations = listConversations;
    },
    async setChatSelected({ state }, payload) {
      let messages = await ChatService.getConversationMessages(payload.id)
      state.chatSelected = payload
      state.chatMessages = messages
      state.chatConversations = state.chatConversations.map(e => {
        return {
          ...e,
          unreadMessage: e.id == payload.id ? false : e.unreadMessage
        }
      });
    },
    async onReceivedChatMessage({ state }, payload) {
      let messageData = {
        dateCreated: payload.messageData.dateSent,
        fromUserId: payload.messageData.fromUserId,
        fromUserName: payload.messageData.fromUserName,
        toUserId: payload.messageData.toUserId,
        message: payload.messageData.message,
        id: payload.messageData.id
      };
      if (state.chatSelected && state.chatSelected.id == payload.messageData.conversationId) {
        state.chatMessages.push(messageData)
      }
      let listConversations = await ChatService.getAllConversations()
      state.chatConversations = listConversations.map(e => {
        return {
          ...e,
          unreadMessage: e.id == payload.messageData.conversationId ? true : false
        }
      });
      if(payload.messageData.fromUserId != state.userLogged.documento){
        showNotificationPopup({
          message: messageData.message,
          title: messageData.fromUserName +' dice:'
        })
      }
    },
    setNoveltyToEdit({state},payload){
      state.noveltyToEdit = payload
    },
    connectToChat({state, getters, dispatch}){
      if (getters.isLogged) {
        Vue.prototype.socket = io('https://websockets-chat-ePY8ZUXfUhve.alianzadx.com', { query: { id: state.userLogged.documento } }); // Cambia la URL por la de tu servidor
        Vue.prototype.socket.on('front-chat-message', msg => {
          dispatch('onReceivedChatMessage', msg)
        });
        Vue.prototype.socket.on('front-new-novelties', msg => {
          dispatch('onReceivedNewNovelties', msg)
        });
        Vue.prototype.socket.on("error", (error) => {
          // Captura y maneja errores de conexión
          state.socket.isConnected = false;
          console.error("Error de conexión con el servidor:", error);
        });
        Vue.prototype.socket.on("connect", () => {
          // Captura y maneja errores de conexión
          state.socket.isConnected = true;
          console.error("Conexion exitosa:");
        });
      }
    },
    async emitMessageChatToSocket(store,messageData){
      await Vue.prototype.socket.emit('chat-message', {
        messageData: messageData
      });
    },
    async emitNewNoveltiesToSocket(store,messageData){
      await Vue.prototype.socket.emit('new-novelties', messageData);
    },
    async onReceivedNewNovelties(store, messageData) {
      if(messageData.fromUserId != store.state.userLogged.documento){
        showNotificationPopup({
          message: messageData.manejo,
          title: 'Nueva Novedad en: '+messageData.titulo
        })
      }
      store.dispatch('getAllNovelties')
    },
    async getAllNovelties({state},payload){
      state.listNovelties = [];
      let novelties = await NoveltiesService.getAll(payload.page,payload.filters);
      state.listNovelties = novelties.data.map(e => {
          let arraySedes = [];
          if (e.sedes) {
              arraySedes = JSON.parse(e.sedes)
          }
          return {
              ...e,
              sedes: arraySedes,
              fechaCreacion: moment(e.fechaCreacion).format('YYYY-MM-DD hh:mm a')
          }
      })
      state.pagination = {
        currentPage: novelties.current_page,
        totalRows: novelties.total,
        perPage: novelties.per_page
      }
    }
  }
})
