import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function save(data) {
    try {
        let response = await axios.post(API_CONSTANTS.ENDPOINTS.NOVEDADES.GUARDAR_NOVEDAD, data);
        return response.data
    } catch (error) {
        return error.response.data
    }
}

async function getAll(page,filters) {
    try {
        let response = await axios.post(API_CONSTANTS.ENDPOINTS.NOVEDADES.LISTAR_NOVEDADES_PUBLICADAS+`?page=${page}`,filters);
        return response.data
    } catch (error) {
        return error.response.data
    }
}

async function detailsNovelty(noveltyId) {
    try {
        let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.NOVEDADES.DETAILS_NOVELTY}/${noveltyId}`)
        return response.data;
    } catch (error) {
        return error.response.data
    }
}

async function update(data, noveltyId) {
    try {
        let response = await axios.put(`${API_CONSTANTS.ENDPOINTS.NOVEDADES.ACTUALIZAR_NOVEDAD}/${noveltyId}`, data);
        return response.data
    } catch (error) {
        return error.response.data
    }
}

export default {
    save,
    update,
    getAll,
    detailsNovelty
}