<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-5">
      <b-col class="text-center">
        <img class="mr-2" style="height: 5rem;" src="https://www.alianzadiagnostica.com/images/logo-img.png"
          alt="Alianza Diangnística">
        <img class="mr-2" style="height: 5rem;" src="https://www.alianzadiagnostica.com/images/logo2.png"
          alt="Alianza Diangnística">
        <img style="height: 5rem;" src="https://www.alianzadiagnostica.com/images/logo3.png" alt="Alianza Diangnística">
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" style="height: 70vh;">
      <b-col cols="12" md="7">
        <b-card style="border-radius: 3%;">
          <b-container>

            <b-row class="text-center mb-4">
              <b-col class="text-muted">
                <h3>Sistema de Información</h3>
                <h1 style="font-family:fantasy">Reporte Al Día</h1>
              </b-col>
            </b-row>

            <b-row align-h="center">
              <b-col cols="12" md="7">
                <b-card-body>
                  <!-- <b-card-title class="text-center mb-4">Iniciar Sesión</b-card-title> -->
                  <b-card-text class="text-center">
                    <b-input-group class="mb-4">
                      <b-input-group-prepend is-text>
                        <b-icon icon="person"></b-icon>
                      </b-input-group-prepend>
                      <b-form-input type="text" placeholder="Usuario" required v-model="form.usuario"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mb-4">
                      <b-input-group-prepend is-text>
                        <b-icon icon="lock"></b-icon>
                      </b-input-group-prepend>
                      <b-form-input type="password" placeholder="Clave" required v-model="form.password"></b-form-input>
                    </b-input-group>
                    <b-form-group>
                      <b-button block variant="primary" @click="login()">Ingresar</b-button>
                    </b-form-group>
                  </b-card-text>

                  <!-- <b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title> -->
                </b-card-body>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import auth from "./services/Auth";
export default {

  data: () => ({
    form: {
      usuario: "",
      password: "",
    },
    errors: [],
    isValidForm: false,
    error_message: ""
  }),
  beforeMount() {
    if (this.$store.getters.isLogged) {
      this.$router.replace("/");
    }
  },
  mounted() {
    // const wrapper = document.querySelector(".wrapper"),
    //   loginHeader = document.querySelector(".login header");
    // loginHeader.addEventListener("click", () => {
    //   wrapper.classList.add("active");
    // });
  },
  methods: {
    async login() {
      const data = {
        usuario: this.form.usuario,
        password: this.form.password
      };
      auth.login(data).then(() => {
        this.$store.dispatch('connectToChat')
        this.$router.push("/");
      }).catch(error => {
        console.log('error', error)
        this.$newSnackbarWarning(error)
      });
    }
  },
  watch: {
    form: {
      handler(newForm) {
        this.isValidForm = newForm.usuario !== "" && newForm.password !== "";
      },
      deep: true
    }
  },
};
</script>

<style lang="scss"></style>