<template>
  <div class="register-div">
    <div class="session">
    <div class="left">
    
    </div>
    <div class="log-in form"> 
      <h4>Registrame <span>BASE Alianza</span></h4>
      <p>Por favor llene la siguiente información:</p>
      <div class="floating-label">
        <input placeholder="Nombre" type="text" name="nameUser" id="nameUser" autocomplete="off" v-model="formData.name">
        <label for="email">Nombre:</label>
        <div class="icon">
        </div>
      </div>
      <div class="floating-label">
        <input placeholder="Email" type="email" name="email" id="email" autocomplete="off" v-model="formData.email">
        <label for="email">Email:</label>
        <div class="icon">
        </div>
      </div>
      <div class="floating-label">
        <input placeholder="Clave" type="password" name="password" id="password" autocomplete="off" v-model="formData.password">
        <label for="password">Password:</label>
        <div class="icon">
        </div>
      </div>
      <div class="floating-label">
        <input placeholder="Confirmar Clave" type="password" name="passwordRepeat" id="passwordRepeat" autocomplete="off" v-model="formData.confirmPassword">
        <label for="password">Confirme Password:</label>
        <div class="icon">
        </div>
      </div>
      <button :disabled="!isValidForm" @click="register()">Registrarme</button>   
      <b-link class="discrete" to="login">Ya tengo una cuenta</b-link>   
    </div>
  </div>
  </div>
</template>

<script>
import auth from "./services/Auth";
export default {
  data: () => ({
    formData: {
      name: '',
      email: "",
      password: "",
      confirmPassword: ""
    },
    isValidForm: false
  }),
  methods: {
    async register() {
      try {
        const data = {
          name: this.formData.name,
          email: this.formData.email,
          password: this.formData.password
        }
        await auth.register(data);
        this.$router.push("/login");
      } catch (error) {
        console.log(error)
      }
    }
  },
  watch: {
    formData: {
      handler(newFormData){
        this.isValidForm = newFormData.password == newFormData.confirmPassword && newFormData.name !== '' && newFormData.email !== ''
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>

</style>