<template>
  <b-navbar class="navbar-top">
    <img style="min-width: 35px;" src="https://www.alianzadiagnostica.com/images/logo-img.png" alt="Alianza Diangnística">
    <b-navbar-nav class="ml-5" id="navbar-desktop">
      <b-dropdown text="Novedades" size="sm" class="m-2" v-if="checkPermission('novelties-show')" variant="primary"
        id="menu-novedades">
        <b-dropdown-item to="/novedades">Tablero</b-dropdown-item>
        <b-dropdown-item to="/novedades/crear" v-if="checkPermission('novelties-admin')">Crear
          Novedad</b-dropdown-item>
      </b-dropdown>
      <b-dropdown text="Biblioteca Cups" size="sm" class="m-2" v-if="checkPermission('library-show')" variant="primary"
        id="menu-biblioteca">
        <b-dropdown-item to="/biblioteca" v-if="checkPermission('library-show')">Biblioteca</b-dropdown-item>
      </b-dropdown>
      <b-dropdown text="Administración" size="sm" class="m-2"
        v-if="checkPermission('users-show') || checkPermission('roles-show') || checkPermission('type-novelties-show') || checkPermission('causes-show')"
        variant="primary" id="menu-administracion">
        <b-dropdown-item to="/usuarios" v-if="checkPermission('users-show')">Usuarios</b-dropdown-item>
        <b-dropdown-item to="/roles" v-if="checkPermission('roles-show')">Roles</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item to="/tipo-novedades" v-if="checkPermission('type-novelties-show')">Tipos de
          Novedades</b-dropdown-item>
        <b-dropdown-item to="/causas" v-if="checkPermission('causes-show')">Causas</b-dropdown-item>
      </b-dropdown>
      <b-button size="sm" to="/chat" variant="primary" class="m-2" id="menu-chat">
        <b-icon icon="circle-fill" scale=".6"
          :variant="$store.state.socket.isConnected ? 'success' : 'danger'"></b-icon>Chat
      </b-button>
    </b-navbar-nav>
    <b-button id="btn-logout" size="sm" variant="danger" @click="logout()">Salir
    </b-button>
  </b-navbar>
</template>
<script>
import auth from "../views/Auth/services/Auth"
export default {
  name: 'Navbar',
  data() {
    return {
      iconToggleSidebar: 'chevron-left'
    }
  },
  methods: {
    async logout() {
      auth.logout().then(() => {
        this.$router.push('/login')
      }).catch(err => {
        console.log('err', err)
      })

    },
    async toggleSidebar() {
      await this.$store.dispatch('toggleSidebar')
    }
  }
}
</script>

<style scoped>
.btn-nav-menu {
  background-color: #2A2F43 !important;
  color: white !important;
}

.text-cargo {
  font-size: 0.8rem;
}

.navbar-nav {
  height: 50px;
  font-size: .7rem;
}

.navbar-top {
  border-bottom: solid 1px #ccc;
  box-shadow: 0px 0px 5px 1px black !important;
  padding: 0px 10px;
  margin-bottom: 3px;
}
</style>